import { Suspense, LazyExoticComponent, ComponentType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

// project imports
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: LazyExoticComponent<() => JSX.Element> | ComponentType<React.ReactNode>) => (props: any) => (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            window.location.reload();
        }}
    >
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    </ErrorBoundary>
);

export default Loadable;
