import { DefaultRootStateProps } from 'types';
import { PagesPayloadProps } from 'types/pages';
import {
    CREATO_ADD_PAGE,
    CREATO_SWAP_PAGE,
    CREATO_SELECTED_PAGE,
    CREATOR_ALTER_PAGE,
    CREATO_DELETE_PAGE,
    CREATO_ALTER_SNAP,
    CREATO_PAGE_RESET
} from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['pages'] = {
    pages: [],
    selectedPage: null
};

const pagesReducer = (state = initialState, action: PagesPayloadProps) => {
    switch (action.type) {
        case CREATO_PAGE_RESET: {
            state.pages = [];
            state.selectedPage = null;
            return {
                ...state
            };
        }
        case CREATO_ADD_PAGE: {
            const obj: any = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                pages: [...state.pages, obj]
            };
        }

        case CREATO_SELECTED_PAGE: {
            return {
                ...state,
                selectedPage: action.payload
            };
        }

        case CREATOR_ALTER_PAGE: {
            console.log('ation', action.payload);
            const id: any = action.payload.id;
            const obj: any = action.payload.obj;
            obj.uid = uuidv4();
            state.pages[id] = obj;

            // // state.pages[id].title = obj.title;
            // state.pages[id].selectedBackgroundImage = obj.selectedBackgroundImage;
            // state.pages[id].selectedCharacters = obj.selectedCharacters;
            // state.pages[id].selectedAddText = obj.selectedAddText;
            // state.pages[id].selectedBubbles = obj.selectedBubbles;
            // state.pages[id].snapShot = obj.snapShot;
            return {
                ...state
            };
        }

        case CREATO_ALTER_SNAP: {
            console.log('ation', action.payload);
            const id: any = action.payload.id;
            const obj: any = action.payload.obj;
            if (state.pages.length > 0) state.pages[id].snapShot = obj;

            // // state.pages[id].title = obj.title;
            // state.pages[id].selectedBackgroundImage = obj.selectedBackgroundImage;
            // state.pages[id].selectedCharacters = obj.selectedCharacters;
            // state.pages[id].selectedAddText = obj.selectedAddText;
            // state.pages[id].selectedBubbles = obj.selectedBubbles;
            // state.pages[id].snapShot = obj.snapShot;
            return {
                ...state
            };
        }

        case CREATO_SWAP_PAGE: {
            const start = action.payload.start;
            const end = action.payload.end;
            state.pages.splice(end, 0, state.pages.splice(start, 1)[0]);
            return {
                ...state
            };
        }

        case CREATO_DELETE_PAGE: {
            const uid = action.payload.uid;
            console.log(uid);
            const page = state.pages.filter((p) => p.uid !== uid);
            return {
                ...state,
                pages: page
            };
        }

        default:
            return {
                ...state
            };
    }
};

export default pagesReducer;
