import { DefaultRootStateProps } from 'types';
import { categorySeletPayloadProps } from 'types/categoriesSelet';
import { CREATO_SELECT_CATEGORY } from './actions';

const initialState: DefaultRootStateProps['categorySelect'] = {
    selectedCategory: 'background'
};

const categorySelectReducer = (state = initialState, action: categorySeletPayloadProps) => {
    switch (action.type) {
        case CREATO_SELECT_CATEGORY: {
            return {
                ...state,
                selectedCategory: action.payload
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default categorySelectReducer;
