import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from './accountReducer';
import addTextReducer from './addTextReducer';
import backgroundsReducer from './backgroundsReducer';
import bubblesReducer from './bubblesReducer';
import optionReducer from './optionReducer';

// reducer import
import cartReducer from './cartReducer';
import categorySelectReducer from './categorySelectReducer';
import charactersReducer from './charactersReducer';
import modalReducer from './modalReducer';
import pagesReducer from './pagesReducer';
import SnapReducer from './snapShotReducer';
import storiesReducer from './storiesReducer';
import titleReducer from './titleReducer';
import searchReducer from './searchReducer';
import communityReducer from './communityReducer';
import commentReducer from './commentReducer';
import userReducer from './userDetails';
// import kanbanReducer from './kanbanReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    // snackbar: snackbarReducer,
    // kanban: kanbanReducer
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    account: accountReducer,
    backgrounds: backgroundsReducer,
    categorySelect: categorySelectReducer,
    characters: charactersReducer,
    bubbles: bubblesReducer,
    title: titleReducer,
    addText: addTextReducer,
    pages: pagesReducer,
    modal: modalReducer,
    story: storiesReducer,
    snapShot: SnapReducer,
    search: searchReducer,
    community: communityReducer,
    comment: commentReducer,
    option: optionReducer,
    user: userReducer
});

export default reducer;
