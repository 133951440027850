/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { DefaultRootStateProps } from 'types';
import { AddTextPayloadProps } from 'types/addText';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
    CREATO_ADD_TEXT,
    CREATO_SELECT_ADD_TEXT,
    CREATO_SELECT_ADD_TEXT_POSITION,
    CREATO_SELECT_ADD_TEXT_TEXTCONTENT,
    CREATO_SELECT_ADD_TEXT_DELETE,
    CREATO_SELECT_ADD_TEXT_RESET,
    CREATO_PAGE_SELECTED_ADD_TEXT
} from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['addText'] = {
    addText: [],
    selectedText: []
};

const addTextReducer = (state = initialState, action: AddTextPayloadProps) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state.addText = [];
            state.selectedText = [];

            return {
                state
            };
        }
        case CREATO_ADD_TEXT: {
            return {
                ...state,
                addText: action.payload
            };
        }
        case CREATO_SELECT_ADD_TEXT: {
            const obj = action.payload;
            obj.uid = uuidv4();
            const text = [...state.selectedText, obj];
            console.log('text', text);

            return {
                ...state,
                selectedText: text
            };
        }
        case CREATO_PAGE_SELECTED_ADD_TEXT: {
            return {
                ...state,
                selectedText: action.payload
            };
        }

        case CREATO_SELECT_ADD_TEXT_TEXTCONTENT: {
            const uid = action.payload.id;

            const selectedText = _.cloneDeep(state.selectedText);
            const changeSelectedText = selectedText.map((obj: any) => {
                if (obj.id === uid) {
                    obj.textContent = action.payload.textContent;
                }
                return obj;
            });
            console.log('change', changeSelectedText);
            //   selectedText!.textContent = action.payload;
            return {
                ...state,
                selectedText: changeSelectedText
            };
        }

        case CREATO_SELECT_ADD_TEXT_POSITION: {
            const uid = action.payload.id;
            const selectedText = _.cloneDeep(state.selectedText);
            const changeSelectedText = selectedText.map((obj: any) => {
                if (obj.id === uid) {
                    obj.positionX = action.payload.positionX;
                    obj.positionY = action.payload.positionY;
                }
                return obj;
            });

            return {
                ...state,
                selectedText: changeSelectedText
            };
        }

        case CREATO_SELECT_ADD_TEXT_DELETE: {
            const uid = action.payload.id;
            const selectedText = _.cloneDeep(state.selectedText);
            const findAndDelete = selectedText.filter((obj: any) => obj.id !== uid);
            return {
                ...state,
                selectedText: findAndDelete
            };
        }

        case CREATO_SELECT_ADD_TEXT_RESET: {
            state.addText = [];
            state.selectedText = [];
            return {
                ...state
            };
        }

        default:
            return {
                ...state
            };
    }
};

export default addTextReducer;
