const config: {
    basename: string;
    defaultPath: string;
    i18n: string;
    jwt: {
        secret: string;
        timeout: string;
    };
} = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    }
};

export default config;
