import { SET_SEARCH } from './actions';
import { DefaultRootStateProps } from 'types';
import { SearchPayloadProps } from 'types/search';

const initialState: DefaultRootStateProps['search'] = {
    keyWord: ''
};

const searchReducer = (state = initialState, action: SearchPayloadProps) => {
    switch (action.type) {
        case SET_SEARCH: {
            const obj = action.payload;
            return {
                ...state,
                keyWord: obj
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};
export default searchReducer;
