import { DefaultRootStateProps } from 'types';
import { TitlePayloadProps } from 'types/title';
import { CREATO_TITLE, CREATO_TITLE_RESET } from './actions';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState: DefaultRootStateProps['title'] = {
    title: ''
};

const titleReducer = (state = initialState, action: TitlePayloadProps) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state.title = '';
            return {
                state
            };
        }
        case CREATO_TITLE: {
            return {
                title: action.payload
            };
        }

        case CREATO_TITLE_RESET: {
            return {
                title: ''
            };
        }
        default:
            return state;
    }
};

export default titleReducer;
