import { DefaultRootStateProps } from 'types';
import { BackgroundsPayloadProps } from 'types/backgrounds';
import { CREATO_BACKGROUNDS, CREATO_SELECT_BACKGROUND, CREATO_SELECT_BACKGROUND_RESET } from './actions';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState: DefaultRootStateProps['backgrounds'] = {
    backgroundImages: [],
    selectedBackgroundImage: {}
};

const backgroundsReducer = (state = initialState, action: BackgroundsPayloadProps) => {
    switch (action.type) {
        case CREATO_BACKGROUNDS: {
            return {
                ...state,
                backgroundImages: action.payload
            };
        }

        case CREATO_SELECT_BACKGROUND: {
            return {
                ...state,
                selectedBackgroundImage: action.payload
            };
        }

        case CREATO_SELECT_BACKGROUND_RESET: {
            return {
                ...state,
                selectedBackgroundImage: {}
            };
        }
        case LOCATION_CHANGE: {
            state.backgroundImages = [];
            state.selectedBackgroundImage = {};

            return {
                state
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default backgroundsReducer;
