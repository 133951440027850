import { DefaultRootStateProps } from 'types';
import { CommentsPayloadProps } from 'types/comments';
import { CREATO_ADD_COMMENT } from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['comment'] = {
    comments: []
};

const commentReducer = (state = initialState, action: CommentsPayloadProps) => {
    switch (action.type) {
        case CREATO_ADD_COMMENT: {
            const obj = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                comments: obj
            };
        }

        default:
            return {
                ...state
            };
    }
};

export default commentReducer;
