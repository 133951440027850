// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// action - cart
export const ADD_PRODUCTS = '@cart/ADD_PRODUCTS';
export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const UPDATE_QUANTITY = '@cart/UPDATE_QUANTITY';
export const NEXT_STEP = '@cart/NEXT_STEP';
export const BACK_STEP = '@cart/BACK_STEP';
export const SET_STEP = '@cart/SET_STEP';
export const SET_BILLING_ADDRESS = '@cart/SET_BILLING_ADDRESS';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';
export const SET_SHIPPING_CHARGE = '@cart/SET_SHIPPING_CHARGE';
export const SET_PAYMENT_METHOD = '@cart/SET_PAYMENT_METHOD';
export const SET_PAYMENT_CARD = '@cart/SET_PAYMENT_CARD';
export const RESET_CART = '@cart/RESET_CART';

// action - kanban
export const ADD_COLUMN = '@kanban/ADD_COLUMN';
export const EDIT_COLUMN = '@kanban/EDIT_COLUMN';
export const DELETE_COLUMN = '@kanban/DELETE_COLUMN';
export const UPDATE_COLUMN_ORDER = '@kanban/UPDATE_COLUMN_ORDER';

export const ADD_ITEM = '@kanban/ADD_ITEM';
export const EDIT_ITEM = '@kanban/EDIT_ITEM';
export const DELETE_ITEM = '@kanban/DELETE_ITEM';
export const UPDATE_COLUMN_ITEM_ORDER = '@kanban/UPDATE_COLUMN_ITEM_ORDER';
export const SELECT_ITEM = '@kanban/SELECT_ITEM';
export const ADD_ITEM_COMMENT = '@kanban/ADD_ITEM_COMMENT';

export const UPDATE_STORY_ORDER = '@kanban/UPDATE_STORY_ORDER';
export const UPDATE_STORY_ITEM_ORDER = '@kanban/UPDATE_STORY_ITEM_ORDER';
export const DELETE_STORY = '@kanban/DELETE_STORY';
export const ADD_STORY = '@kanban/ADD_STORY';
export const EDIT_STORY = '@kanban/EDIT_STORY';
export const ADD_STORY_COMMENT = '@kanban/ADD_STORY_COMMENT';

// CREATO ACTIONS
export const CREATO_BACKGROUNDS = '@creato/CREATO_BACKGROUNDS';
export const CREATO_TITLE = '@creato/CREATO_TITLE';
export const CREATO_TITLE_RESET = '@creato/CREATO_TITLE_RESET';
export const CREATO_ADD_TEXT = '@creato/CREATO_ADD_TEXT';
export const CREATO_SELECT_ADD_TEXT = '@creato/CREATO_SELECT_ADD_TEXT';
export const CREATO_SELECT_ADD_TEXT_RESET = '@creato/CREATO_SELECT_ADD_TEXT_RESET';
export const CREATO_SELECT_ADD_TEXT_TEXTCONTENT = '@creato/CREATO_SELECT_ADD_TEXT_TEXTCONTENT';
export const CREATO_SELECT_ADD_TEXT_POSITION = '@creato/CREATO_SELECT_ADD_TEXT_POSITION';
export const CREATO_SELECT_ADD_TEXT_DELETE = '@creato/CREATO_SELECT_ADD_TEXT_DELETE';
export const CREATO_PAGE_SELECTED_ADD_TEXT = '@creato/CREATO_PAGE_SELECTED_ADD_TEXT';
export const CREATO_SELECT_BACKGROUND = '@creato/CREATO_SELECT_BACKGROUND';
export const CREATO_SELECT_BACKGROUND_RESET = '@creato/CREATO_SELECT_BACKGROUND_RESET';
export const CREATO_SELECT_CATEGORY = '@creato/CREATO_SELECT_CATEGORY';
export const CREATO_CHARACTERS = '@creato/CREATO_CHARACTERS';
export const CREATO_CHILD_CHARACTERS = '@creato/CREATO_CHILD_CHARACTERS';
export const CREATO_SELECTED_CHILD_CHARACTERS = '@creato/CREATO_SELECTED_CHILD_CHARACTERS';
export const CREATO_PAGE_SELECTED_CHILD_CHARACTERS = '@creato/CREATO_PAGE_SELECTED_CHILD_CHARACTERS';
export const CREATO_SELECTED_CHILD_CHARACTERS_RESET = '@creato/CREATO_SELECTED_CHILD_CHARACTERS_RESET';
export const CREATO_SELECTED_CHILD_POSITION = '@creato/CREATO_SELECTED_CHILD_POSITION';
export const CREATO_SELECTED_CHILD_RESIZE = '@creato/CREATO_SELECTED_CHILD_RESIZE';
export const CREATO_SELECTED_CHILD_DELETE = '@creato/CREATO_SELECTED_CHILD_DELETE';
export const CREATO_SELECTED_CHILD_FLIP = '@creato/CREATO_SELECTED_CHILD_FLIP';
export const CREATO_SELECTED_CHILD_COPY = '@creato/CREATO_SELECTED_CHILD_COPY';

// action - modal
export const MODAL_OPEN = '@creato/MODAL_OPEN';
export const MODAL_CLOSE = '@creato/MODAL_CLOSE';

// action - pages
export const CREATO_ADD_PAGE = '@creato/CREATO_ADD_PAGE';
export const CREATO_DELETE_PAGE = '@creato/CREATO_DELETE_PAGE';
export const CREATO_SELECTED_PAGE = '@creato/CREATO_SELECTED_PAGE';
export const CREATOR_ALTER_PAGE = '@creato/CREATO_ALTER_PAGE';
export const CREATO_SWAP_PAGE = '@creato/CREATOR_SWAP_PAGE';
export const CREATO_PAGE_SIZE = '@creato/CREATO_PAGE_SIZE';
export const CREATO_ALTER_SNAP = '@creato/CREATO_ALTER_SNAP';
export const CREATO_PAGE_RESET = '@creato/CREATO_PAGE_RESET';

// action - bubbles
export const CREATO_BUBBLES = '@creato/CREATO_BUBBLES';
export const CREATO_CHILD_BUBBLES = '@creato/CREATO_CHILD_BUBBLES';
export const CREATO_SELECTED_BUBBLE_CHILD_CHARACTERS = '@creato/CREATO_SELECTED_BUBBLE_CHILD_CHARACTERS';
export const CREATO_SELECTED_BUBBLE_CHILD_POSITION = '@creato/CREATO_SELECTED_BUBBLE_CHILD_POSITION';

export const CREATO_SELECTED_BUBBLE_CHILD_DELETE = '@creato/CREATO_SELECTED_BUBBLE_CHILD_DELETE';
export const CREATO_SELECTED_BUBBLE_CHILD_FLIP = '@creato/CREATO_SELECTED_BUBBLE_CHILD_FLIP';
export const CREATO_SELECTED_BUBBLE_CHILD_COPY = '@creato/CREATO_SELECTED_BUBBLE_CHILD_COPY';
export const CREATO_SELECTED_BUBBLE_CHILD_RESIZE = '@creato/CREATO_SELECTED_BUBBLE_CHILD_RESIZE';
export const CREATO_SELECTED_CHILD_BUBBLE_RESET = '@creato/CREATO_SELECTED_CHILD_BUBBLE_RESET';
export const CREATO_PAGE_SELECTED_CHILD_BUBBLES = '@creato/CREATO_PAGE_SELECTED_CHILD_BUBBLES';
export const CREATO_PAGE_SELECTED_CHILD_BUBBLE_TEXT = '@creato/CREATO_PAGE_SELECTED_CHILD_BUBBLE_TEXT';

// action - stories
export const CREATO_STORIES = '@creato/CREATO_STORIES';
export const CREATO_STORIES_SELECTED_STATUS = '@creato/CREATO_STORIES_SELECTED_STATUS';

// action - snapshot
export const CREATO_SELECT_SNAP = '@creato/CREATO_SELECT_SNAP';
export const CREATO_RESET_SNAP = '@creato/CREATO_RESET_SNAP';

// search
export const SET_SEARCH = '@creato/CREATO_SET_SEARCH';

// community
export const CREATO_COMMUNITIES = '@creato/CREATO_COMMUNITIES';
export const CREATO_SELECTED_COMMUNITY = '@creato/CREATO_SELECTED_COMMUNITY';
export const CREATO_DELETE_COMMUNITY = '@creato/CREATO_DELETE_COMMUNITY';

// comment
export const CREATO_ADD_COMMENT = '@creato/CREATO_ADD_COMMENTS';
export const CREATO_EDIT_COMMENT = '@creato/CREATO_ADD_COMMENTS';

// option
export const CREATO_ADD_OPTIONS = '@creato/CREATO_ADD_OPTIONS';

// user
export const CREATO_ADD_USER = '@creato/CREATO_ADD_USER';
