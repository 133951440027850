import { DefaultRootStateProps } from 'types';
import { OptionsPayloadProps } from 'types/options';
import { CREATO_ADD_OPTIONS } from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['option'] = {
    options: []
};
const optionReducer = (state = initialState, action: OptionsPayloadProps) => {
    switch (action.type) {
        case CREATO_ADD_OPTIONS: {
            const obj = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                options: obj
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default optionReducer;
