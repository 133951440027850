import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
// const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));

// ==============================|| AUTH ROUTING ||============================== //
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const AuthErrorPage = Loadable(lazy(() => import('views/pages/AuthError/AuthError')));
const CreatePage = Loadable(lazy(() => import('views/pages/Create/Create')));
const Authentication = Loadable(lazy(() => import('views/pages/Authentication/Authentication')));
const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/error',
            element: <AuthErrorPage />
        },
        {
            path: '/auth',
            element: <Authentication />
        }
    ]
};

export default LoginRoutes;
