import { DefaultRootStateProps } from 'types';
import { CommunityPayloadProps } from 'types/community';
import { CREATO_COMMUNITIES, CREATO_SELECTED_COMMUNITY, CREATO_DELETE_COMMUNITY } from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['community'] = {
    communities: [],
    selectedCommunity: null
};

const communityReducer = (state = initialState, action: CommunityPayloadProps) => {
    switch (action.type) {
        case CREATO_COMMUNITIES: {
            const obj = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                communities: obj
            };
        }

        case CREATO_SELECTED_COMMUNITY: {
            const obj = action.payload;

            return {
                ...state,
                selectedCommunity: obj
            };
        }

        case CREATO_DELETE_COMMUNITY: {
            const obj = action.payload;
            state.communities.splice(obj, 1);

            return {
                ...state
            };
        }
        default:
            return {
                ...state
            };
    }
};
export default communityReducer;
