/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { DefaultRootStateProps } from 'types';
import { BubblesPayloadProps } from 'types/bubbles';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
    CREATO_BUBBLES,
    CREATO_CHILD_BUBBLES,
    CREATO_PAGE_SELECTED_CHILD_BUBBLES,
    CREATO_PAGE_SELECTED_CHILD_BUBBLE_TEXT,
    CREATO_SELECTED_BUBBLE_CHILD_CHARACTERS,
    CREATO_SELECTED_BUBBLE_CHILD_COPY,
    CREATO_SELECTED_BUBBLE_CHILD_DELETE,
    CREATO_SELECTED_BUBBLE_CHILD_FLIP,
    CREATO_SELECTED_BUBBLE_CHILD_POSITION,
    CREATO_SELECTED_BUBBLE_CHILD_RESIZE,
    CREATO_SELECTED_CHILD_BUBBLE_RESET
} from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['bubbles'] = {
    bubbleImages: [],
    bubbleChildImages: [],
    selectedBubbleImage: []
};

const bubblesReducer = (state = initialState, action: BubblesPayloadProps) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state.bubbleImages = [];
            state.bubbleChildImages = [];
            state.selectedBubbleImage = [];
            return {
                ...state
            };
        }
        case CREATO_BUBBLES: {
            return {
                ...state,
                bubbleImages: action.payload
            };
        }

        case CREATO_CHILD_BUBBLES: {
            const { childObjects: _, ...bubble } = action.payload.bubble;
            return {
                ...state,
                bubbleChildImages: [bubble, ...action.payload.item]
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_CHARACTERS: {
            const obj: any = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                selectedBubbleImage: [...state.selectedBubbleImage, obj]
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_POSITION: {
            const id = action.payload.id;
            const positionX = action.payload.positionX;
            const positionY = action.payload.positionY;

            const bubbleObj = _.cloneDeep(state.selectedBubbleImage);

            const findBubbleIdx = bubbleObj.findIndex((obj: any) => obj.uid === id);

            bubbleObj[findBubbleIdx].positionX = positionX;
            bubbleObj[findBubbleIdx].positionY = positionY;

            return {
                ...state,
                selectedBubbleImage: bubbleObj
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_DELETE: {
            const uid = action.payload;
            const selectedCategory = _.cloneDeep(state.selectedBubbleImage);
            const findAndDelete = selectedCategory.filter((obj: any) => obj.uid !== uid);

            return {
                ...state,
                selectedBubbleImage: findAndDelete
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_FLIP: {
            const uid = action.payload.id;
            const isFlipped = action.payload.isFlipped;

            const bubbleObj = _.cloneDeep(state.selectedBubbleImage);
            const findBubbleIdx = bubbleObj.findIndex((obj: any) => obj.uid === uid);

            bubbleObj[findBubbleIdx].isFlipped = isFlipped;

            return {
                ...state,
                selectedBubbleImage: bubbleObj
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_COPY: {
            const uid = action.payload.id;
            const bubbleObj = _.cloneDeep(state.selectedBubbleImage);

            const findBubbleObj = bubbleObj.find((obj: any) => obj.uid === uid);
            findBubbleObj.uid = uuidv4();

            return {
                ...state,
                selectedBubbleImage: [...state.selectedBubbleImage, findBubbleObj]
            };
        }

        case CREATO_SELECTED_BUBBLE_CHILD_RESIZE: {
            const id = action.payload.id;
            const objectHeight = action.payload.objectHeight;
            const objectWidth = action.payload.objectWidth;

            const bubbleObject = _.cloneDeep(state.selectedBubbleImage);

            const findBubbleIdx = bubbleObject.findIndex((obj: any) => obj.uid === id);

            bubbleObject[findBubbleIdx].objectHeight = objectHeight;
            bubbleObject[findBubbleIdx].objectWidth = objectWidth;

            return {
                ...state,
                selectedBubbleImage: bubbleObject
            };
        }

        case CREATO_SELECTED_CHILD_BUBBLE_RESET: {
            state.selectedBubbleImage = [];
            return {
                ...state
            };
        }

        case CREATO_PAGE_SELECTED_CHILD_BUBBLES: {
            return {
                ...state,
                selectedBubbleImage: action.payload
            };
        }

        case CREATO_PAGE_SELECTED_CHILD_BUBBLE_TEXT: {
            const id = action.payload.id;
            const textContent = action.payload.textContent;
            const bubbleObject = _.cloneDeep(state.selectedBubbleImage);
            const findBubbleIdx = bubbleObject.findIndex((obj: any) => obj.uid === id);
            bubbleObject[findBubbleIdx].textContent = textContent;
            return {
                ...state,
                selectedBubbleImage: bubbleObject
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default bubblesReducer;
