/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import { DefaultRootStateProps } from 'types';
import { CharactersPayloadProps } from 'types/characters';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
    CREATO_CHARACTERS,
    CREATO_CHILD_CHARACTERS,
    CREATO_PAGE_SELECTED_CHILD_CHARACTERS,
    CREATO_SELECTED_CHILD_CHARACTERS,
    CREATO_SELECTED_CHILD_CHARACTERS_RESET,
    CREATO_SELECTED_CHILD_COPY,
    CREATO_SELECTED_CHILD_DELETE,
    CREATO_SELECTED_CHILD_FLIP,
    CREATO_SELECTED_CHILD_POSITION,
    CREATO_SELECTED_CHILD_RESIZE
} from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['characters'] = {
    characterImages: [],
    characterChildImages: [],
    selectedCharacterImage: []
};

const charactersReducer = (state = initialState, action: CharactersPayloadProps) => {
    switch (action.type) {
        case LOCATION_CHANGE: {
            state.characterImages = [];
            state.characterChildImages = [];
            state.selectedCharacterImage = [];
            return {
                state
            };
        }
        case CREATO_CHARACTERS: {
            return {
                ...state,
                characterImages: action.payload
            };
        }
        case CREATO_CHILD_CHARACTERS: {
            const { childObjects: _, ...character } = action.payload.character;
            return {
                ...state,
                characterChildImages: [character, ...action.payload.item]
            };
        }

        case CREATO_SELECTED_CHILD_CHARACTERS: {
            const obj: any = action.payload;
            obj.uid = uuidv4();
            return {
                ...state,
                selectedCharacterImage: [...state.selectedCharacterImage, obj]
            };
        }

        case CREATO_PAGE_SELECTED_CHILD_CHARACTERS: {
            return {
                ...state,
                selectedCharacterImage: action.payload
            };
        }

        case CREATO_SELECTED_CHILD_POSITION: {
            const id = action.payload.id;
            const positionX = action.payload.positionX;
            const positionY = action.payload.positionY;

            const characterObj = _.cloneDeep(state.selectedCharacterImage);

            const findCharacterIdx = characterObj.findIndex((obj: any) => obj.uid === id);

            characterObj[findCharacterIdx].positionX = positionX;
            characterObj[findCharacterIdx].positionY = positionY;

            return {
                ...state,
                selectedCharacterImage: characterObj
            };
        }

        case CREATO_SELECTED_CHILD_RESIZE: {
            const id = action.payload.id;
            const objectHeight = action.payload.objectHeight;
            const objectWidth = action.payload.objectWidth;

            const characterObj = _.cloneDeep(state.selectedCharacterImage);

            const findCharacterIdx = characterObj.findIndex((obj: any) => obj.uid === id);

            characterObj[findCharacterIdx].objectHeight = objectHeight;
            characterObj[findCharacterIdx].objectWidth = objectWidth;

            return {
                ...state,
                selectedCharacterImage: characterObj
            };
        }

        case CREATO_SELECTED_CHILD_DELETE: {
            const uid = action.payload;
            const selectedCategory = _.cloneDeep(state.selectedCharacterImage);
            const findAndDelete = selectedCategory.filter((obj: any) => obj.uid !== uid);

            return {
                ...state,
                selectedCharacterImage: findAndDelete
            };
        }

        case CREATO_SELECTED_CHILD_FLIP: {
            const uid = action.payload.id;
            const isFlipped = action.payload.isFlipped;

            const characterObj = _.cloneDeep(state.selectedCharacterImage);
            const findCharacterIdx = characterObj.findIndex((obj: any) => obj.uid === uid);

            characterObj[findCharacterIdx].isFlipped = isFlipped;

            return {
                ...state,
                selectedCharacterImage: characterObj
            };
        }

        case CREATO_SELECTED_CHILD_COPY: {
            const uid = action.payload.id;
            const characterObj = _.cloneDeep(state.selectedCharacterImage);

            const findCharacterObj = characterObj.find((obj: any) => obj.uid === uid);
            findCharacterObj.uid = uuidv4();

            return {
                ...state,
                selectedCharacterImage: [...state.selectedCharacterImage, findCharacterObj]
            };
        }

        case CREATO_SELECTED_CHILD_CHARACTERS_RESET: {
            state.selectedCharacterImage = [];
            state.characterChildImages = [];
            state.characterImages = [];

            return {
                ...state
            };
        }
        default:
            return {
                ...state
            };
    }
};

export default charactersReducer;
