import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { initialLoginContextProps, KeyedObject } from 'types';
import { JWTContextType } from 'types/auth';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const chance = new Chance();

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const reduxDispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                // const serviceToken = window.localStorage.getItem('serviceToken');

                const cookies = new Cookies();
                // cookies.set(
                //     'token',
                //     'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ4Y3FMSUR6M3pQRWZDUktHQnVQdnFsM2dBcXNzR3lxeVNGTFVrdTQwY1gwIn0.eyJleHAiOjE2NzQzNjQyNTgsImlhdCI6MTY3MjYzNjI1OCwianRpIjoiZjZjZTk3ZTItOWFmOS00ZTJkLWJkNzctZWYzZGFhOGI2MTEzIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5tcG93ZXItc29jaWFsLmNvbS9hdXRoL3JlYWxtcy9EZW1vLUFiZCIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJmODA3N2Y5My1kNWRkLTQwODYtYTgyZC1jYjBjMGQzOTVlZjMiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJhYmQtbm9kZWpzLW1pY3Jvc2VydmljZSIsInNlc3Npb25fc3RhdGUiOiI3MGUzZTdiOS01Y2JjLTQ2MjctOTY1OS0yZmU4ZGYxZGM2MGYiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiLCJodHRwOi8vbG9jYWxob3N0OjMwMDAiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiRGV2YXNoaXNoIENob3VkaHVyeSIsInByZWZlcnJlZF91c2VybmFtZSI6ImRldmFzaGlzaEBtcG93ZXItc29jaWFsLmNvbSIsImdpdmVuX25hbWUiOiJEZXZhc2hpc2ggQ2hvdWRodXJ5IiwiZmFtaWx5X25hbWUiOiIiLCJlbWFpbCI6ImRldmFzaGlzaEBtcG93ZXItc29jaWFsLmNvbSJ9.m50JVTQhHygMerAf1j98JyQkTQbbGYXELarU5DJbTr771M-cxgOxyeysGBVVBhzHDeBJrbtyjpRz3l6H7xDIzDtcO8yYJ6jwH3Opa3E8QoAhuoR8i13nUOs3iHubR0ZvigbN-csyYnxcDCkQMaeZ9Zn9zlCQojdClz7Wi7sL2cyVMMmvYsKXIPQ_5zJv6mXxk24ZmJYXeFtlaG88qcT5J89OsS8YcBbqL_BUMSfohzsLnTCHXCSRe6cvzKpY7hFBD1JPj_PxYGbNAWpaZp9mnIW9E-_hZfvMSNkWTM3VoYegmtdRhYn31FunYWGOfBWNWhe_8lmlS389uhmZ8_zbfg'
                // );
                const serviceToken = cookies.get('token');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: serviceToken
                        }
                    });

                    reduxDispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: serviceToken
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [reduxDispatch]);

    const login = async (email: string, password: string) => {
        const cookies = new Cookies();

        try {
            const response = await axios.post('/api/auth/signin', { username: email, password: password });

            if (response.status === 200) {
                const { serviceToken, user } = response.data;
                setSession(response.data.access_token.token);

                console.log('response', response.status);

                cookies.set('token', response.data.access_token.token);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: email
                    }
                });
            }
        } catch (error: any) {
            if (error.response.status === 401) toast.error('Wrong email or password');
        }

        // const response = await axios.post('/api/auth/signin', { username: email, password: password });
    };

    const register = async (name: string, email: string, role: string, password: string) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/auth/signup', {
            name: name,
            email: email,
            password: password,
            accountType: role
        });
        const users = response.data;
        console.log(users);

        // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
        //     const localUsers = window.localStorage.getItem('users');
        //     users = [
        //         ...JSON.parse(localUsers!),
        //         {
        //             id,
        //             email,
        //             password,
        //             name: `${firstName} ${lastName}`
        //         }
        //     ];
        // }

        // window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        const cookie = new Cookies();
        cookie.remove('token');

        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
