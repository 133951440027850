import { DefaultRootStateProps } from 'types';
import { TitlePayloadProps } from 'types/title';
import { CREATO_STORIES, CREATO_STORIES_SELECTED_STATUS } from './actions';

const initialState: DefaultRootStateProps['story'] = {
    stories: [],
    selectedStatus: 'published'
};

const storiesReducer = (state = initialState, action: TitlePayloadProps) => {
    switch (action.type) {
        case CREATO_STORIES: {
            return {
                ...state,
                stories: action.payload
            };
        }

        case CREATO_STORIES_SELECTED_STATUS: {
            return {
                ...state,
                selectedStatus: action.payload
            };
        }

        default:
            return state;
    }
};

export default storiesReducer;
