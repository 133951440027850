/**
 * axios setup to use mock service
 */

import axios from 'axios';

const BASEURL = process.env.REACT_APP_BASE_URL;

const axiosServices = axios.create({
    baseURL: BASEURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

// interceptor for http
axiosServices.interceptors.request.use((request) => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
        request.headers!.Authorization = `Bearer ${token}`;
    }
    return request;
});

export default axiosServices;
