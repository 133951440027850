import { DefaultRootStateProps } from 'types';
import { ModalPayloadProps } from 'types/modal';
import { MODAL_CLOSE, MODAL_OPEN } from './actions';

const initialState: DefaultRootStateProps['modal'] = {
    isModalOpen: false,
    type: ''
};

const modalReducer = (state = initialState, action: ModalPayloadProps) => {
    switch (action.type) {
        case MODAL_OPEN: {
            return {
                isModalOpen: true,
                type: action.payload
            };
        }
        case MODAL_CLOSE: {
            return {
                isModalOpen: false
            };
        }
        default:
            return state;
    }
};

export default modalReducer;
