import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MinimalLayout from 'layout/MinimalLayout';
import Groups from 'views/Communities/Groups';

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const HomePage = Loadable(lazy(() => import('views/pages/Home/Home')));
const CreatePage = Loadable(lazy(() => import('views/pages/Create/Create')));
const Stories = Loadable(lazy(() => import('views/pages/Stories/Stories')));
const StoryDetails = Loadable(lazy(() => import('views/pages/Stories/StoryDetails')));
const Published = Loadable(lazy(() => import('components/PublishedStories/Published')));
const Group = Loadable(lazy(() => import('views/Communities/Groups')));
const Profile = Loadable(lazy(() => import('views/pages/Profile/Profile')));
const CommunityStory = Loadable(lazy(() => import('views/Communities/CommunityStory')));
const CategoryUpload = Loadable(lazy(() => import('views/pages/CategoryUpload/CategoryUpload')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/create',
            element: <CreatePage />
        },
        {
            path: '/all-projects',
            element: <Stories />
        },
        {
            path: '/stories/:id',
            element: <StoryDetails />
        },
        {
            path: '/publishedstories/:id/:shared',
            element: <Published />
        },
        {
            path: '/groups',
            element: <Group />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/communityStory/:id/:name',
            element: <CommunityStory />
        },
        {
            path: '/categoryUpload',
            element: <CategoryUpload />
        }
    ]
};

export default MainRoutes;
