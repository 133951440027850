import { DefaultRootStateProps } from 'types';
import { CREATO_SELECT_SNAP, CREATO_RESET_SNAP } from './actions';

const initialState: DefaultRootStateProps['snapShot'] = {
    selectedSnap: ''
};

const SnapReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATO_SELECT_SNAP: {
            return {
                ...state,
                selectedSnap: action.payload.snapShot
            };
        }
        case CREATO_RESET_SNAP: {
            state.selectedSnap = '';
            return {
                ...state
            };
        }

        default:
            return {
                state
            };
    }
};

export default SnapReducer;
