import { DefaultRootStateProps } from 'types';
import { UserPayloadProps } from 'types/user';
import { CREATO_ADD_USER } from './actions';
import { v4 as uuidv4 } from 'uuid';

const initialState: DefaultRootStateProps['user'] = {
    users: []
};
const userReducer = (state = initialState, action: UserPayloadProps) => {
    switch (action.type) {
        case CREATO_ADD_USER: {
            const obj = action.payload;
            return {
                ...state,
                users: obj
            };
        }

        default:
            return {
                ...state
            };
    }
};

export default userReducer;
